<template>
	<div class="home">
		<!-- 欢迎使用 -->
		<h2 class="qjc-texta-c">欢迎使用</h2>
		<div class="home-wrap">
			<div class="home-cell">
				<span>本次登录IP: {{userInfo.thisloginip}}</span> <span>本次登录时间: {{userInfo.thislogintime}}</span>
			</div>
			<div class="home-cell">
				<span>上次登录IP: {{userInfo.lastloginip}}</span> <span>上次登录时间: {{userInfo.lastlogintime}}</span>
			</div>
		</div>
		<div class="charts_wrap" v-if="userInfo.issupper == 1">
			<div class="user_wrap">
				<div class="charts_title">最近一周数据统计</div>
				<div class="user_echarts"></div>
			</div>
		</div>
		<div class="home-bottom">
			本后台系统由上海米因大数据科技开发团队 技术支持
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import { getStatistics } from "@/utils/axios"
	
	export default{
		name: 'home',
		data() {
			return {
				userInfo: {},
			}
		},
		mounted() {
			if(localStorage.getItem('userInfo')) {
				this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
				if(this.userInfo.issupper == 1) {
					this.handleLoad()
				}
			}
		},
		methods: {
			handleLoad() {
				getStatistics({act: 'home'}).then((res) => {
					if(res.code == 200) {
						let result = JSON.parse(window.atob(res.data))
						this.handleUserCharts(result.date, result.userList, result.orderList)
					}
				})
			},
			handleUserCharts(date, userList, orderList) {
				var option = {
						tooltip: {
							trigger: 'axis',
							formatter: function (params) {
								// console.log(params)
								if(params && params.length == 2) {
									return params[0].name + '<br>' + params[0].seriesName + '：' + params[0].value + '<br>' + params[1].seriesName + '：' + params[1].value
								} else if(params && params.length == 1) {
									return params[0].name + '<br>' + params[0].seriesName + '：' + params[0].value
								} else {
									return ''
								}
								
							}
						},
						legend: {
							show: true,
							// data: ['用户注册数', '订单数'],
							data: ['用户注册数', '订单数'],
							icon: 'round',
							textStyle: {
								color: '#777F8F',
								fontSize: 12
							},
							top: 0,
							left: '45%',
							itemHeight: 2,
							itemWidth: 20,
							itemGap: 6,
							zlevel: 100,
						},
						grid: {
							left: '3%',
							right: '3%',
							bottom: '10%',
							top: '10%'
						},
						xAxis: {
							boundaryGap: false,
							axisLabel: {
								color: '#777F8F',
								interval: 0
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: 'true',
								lineStyle: {
									color: '#f5f5f5'
								}
							},
							data: date
						},
						yAxis: {
							name: '',
							type: 'value',
							axisLabel: {
								color: '#777F8F'
							},
							minInterval: 1,
							max: function (value) {
							    return value.max + 10;
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								lineStyle: {
									color: '#f5f5f5'
								}
							}
						},
						series: [
							{
								type: 'line',
								name: '用户注册数',
								// smooth: true,
								data: userList
							},
							{
								type: 'line',
								name: '订单数',
								// smooth: true,
								data: orderList
							},
						],
						color: ['#6883FB','#65D448']
					};
				this.drawChat('.user_echarts', option);
			},
			
			drawChat(el, option){
				window[el] = echarts.init(document.querySelector(el));
				// option.animationDuration = 1000;
				window[el].setOption(option,true);
				window.onresize = window[el].resize;
			},// 绘制图表通用
		},
	}
</script>

<style lang="scss" scoped>
	
	.home {
		padding: 30px 40px;
	}
	.home-wrap {
		padding: 40px;
		// border-bottom: 1px solid #dddddd;
	}
	.home-cell {
		font-size: 15px;
		margin-bottom: 10px;
		span {
			margin-right: 20px;
		}
	}
	.home-bottom {
		text-align: center;
		margin-top: 40px;
		font-size: 12px;
		padding-top: 20px;
		border-top: 1px solid #dddddd;
	}
	
	.charts_wrap {
		
	}
	.charts_title {
		font-size: 20px;
		color: #333;
	}
	.user_echarts {
		width: 100%;
		height: 450px;
		margin-bottom: 40px;
	}
	.order_echarts {
		width: 100%;
		height: 450px;
	}
</style>
